import { i18n } from "./i18n.config";
import { translations as coreTranslations } from "@fraktion/core-frontend";

export const importTranslationModule = (
  lang: string,
  namespace: string
): Promise<{ default: Record<string, any> }> => {
  return import(`../i18n/translations/${lang}/${namespace}.json`);
};

export async function loadTranslations(lang: string) {
  try {
    const normalizedLang = lang.split("-")[0];

    // Load core translations first
    const coreLangTranslations =
      coreTranslations[normalizedLang as keyof typeof coreTranslations];

    if (coreLangTranslations) {
      for (const [namespace, content] of Object.entries(coreLangTranslations)) {
        const normalizedNamespace = namespace
          .replace(/([a-z])([A-Z])/g, "$1-$2")
          .toLowerCase();

        // Load core translations
        i18n.addResourceBundle(
          normalizedLang,
          normalizedNamespace,
          content,
          true,
          false // Changed to false to prevent deep merging initially
        );

        // Try to load and merge satellite project translations
        try {
          const module = await importTranslationModule(
            normalizedLang,
            normalizedNamespace
          );

          // Deep merge satellite translations over core translations
          i18n.addResourceBundle(
            normalizedLang,
            normalizedNamespace,
            module.default,
            true,
            true
          );
        } catch (e) {
          console.debug(`No satellite translations for ${normalizedNamespace}`);
        }
      }
    }

    // Load satellite-specific namespaces that don't exist in core
    const satelliteNamespaces = ["custom"]; // Add your satellite-specific namespaces
    for (const namespace of satelliteNamespaces) {
      try {
        const module = await importTranslationModule(normalizedLang, namespace);
        i18n.addResourceBundle(
          normalizedLang,
          namespace,
          module.default,
          true,
          true
        );
      } catch (e) {
        console.debug(`No satellite translations for ${namespace}`);
      }
    }

    await i18n.reloadResources(normalizedLang);
  } catch (error) {
    console.error("Error loading translations:", error);
  }
}
