import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { loadTranslations } from "./i18n-loader";
import {
  i18n as coreI18n,
  initializeI18n as initializeCoreI18n,
} from "@fraktion/core-frontend";

export const i18n = coreI18n;

export async function initializeI18n() {
  // Initialize core i18n first
  await initializeCoreI18n();

  // Then extend with our config
  await i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: "fr",
      supportedLngs: ["en", "fr"],
      load: "languageOnly",
      defaultNS: "common",
      interpolation: {
        escapeValue: false,
      },
    });

  loadTranslations("fr");

  return i18n;
}
