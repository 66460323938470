import { useEffect } from "react";
import { LoadingSpinner } from "@fraktion/core-frontend";

export function EspaceMembre() {
  useEffect(() => {
    window.location.href = "https://membres.kapi.club/";
  }, []);

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-100 flex flex-col items-center justify-center text-center min-h-screen space-y-2">
      <LoadingSpinner />
      <span className="text-lg text-muted-foreground">
        Redirection vers l'espace membre...
      </span>
    </div>
  );
}

export default EspaceMembre;
